import { decompress, init } from "@bokuweb/zstd-wasm";
import pako from "pako";
import { errorToast } from "./errors";

const decoders: Record<string, (data: Uint8Array) => Promise<Uint8Array>> = {
  gzip: async (data) => pako.ungzip(data),
  zstd: async (data) => {
    await init();
    return decompress(data);
  },
  deflate: async (data) => pako.inflate(data),
};

export type DownloadStatus = {
  downloading: boolean;
  percent: number;
};

function stopDownload(status?: Ref<DownloadStatus>, errorMessage?: string) {
  if (status) status.value.downloading = false;

  if (errorMessage) errorToast(errorMessage);

  return;
}

export async function downloadFile(request: () => Promise<Response> | null, status?: Ref<DownloadStatus>, fileName?: string, fileType?: string) {
  if (status)
    status.value = {
      downloading: true,
      percent: 0,
    };

  try {
    const response = await request();

    if (!response) return stopDownload(status, "Impossibile scaricare il file");

    if (response.status < 200 || response.status >= 300) {
      if (response.status === 403) return stopDownload(status, "Non hai le autorizzazioni per accedere a questa risorsa");

      throw new Error(response.statusText);
    }

    const contentLengthHeader = response.headers.get("content-length");
    const contentLengthRange = response.headers.get("content-range");
    const contentLength = contentLengthHeader ? contentLengthHeader : contentLengthRange ? contentLengthRange.split("/").pop() : null;

    if (!response.body) return stopDownload(status, "Nessuna risposta dal server");

    const total = parseInt(contentLength, 10);
    let loaded = 0;

    const values: Uint8Array<ArrayBufferLike>[] = [];
    const reader = response.body.getReader();

    while (true) {
      const { done, value } = await reader.read();

      if (done) {
        if (status) status.value.downloading = false;
        break;
      }

      const contentEncoding = response.headers.get("content-encoding")?.toLowerCase();

      let decompressedChunk = value;

      if (contentEncoding && contentEncoding in decoders) {
        try {
          decompressedChunk = await decoders[contentEncoding](value);
        } catch (decompressionError) {
          console.error(`Error decompressing data with ${contentEncoding}:`, decompressionError);
          decompressedChunk = value;
        }
      }

      values.push(decompressedChunk);

      loaded += value.byteLength;
      if (status) status.value.percent = Math.floor((loaded / total) * 100);
    }

    const headerFileType = response.headers.get("content-type");

    let headerFileName: string;
    try {
      headerFileName = getFilenameFromContentDisposition(response.headers.get("content-disposition"));
    } catch (error) {}

    const downloadType = (fileType || headerFileType)?.toLowerCase();
    const downloadFileName = fileName || headerFileName;

    const file = new File(values, downloadFileName, { type: downloadType });
    const url = (window.webkitURL || window.URL).createObjectURL(file);

    // const supportedBrowserPreview = ["application/pdf", "image/jpeg", "image/png", "image/gif", "text/plain", "text/html", "audio/mpeg", "audio/ogg", "video/mp4", "video/webm", "video/ogg"];
    const supportedBrowserPreview: string[] = []; // Disabilitato perché il browser non permette di impostare un nome al download del file nella nuova scheda

    const link = document.createElement("a");
    link.className = "hidden";
    link.href = url;

    if (!supportedBrowserPreview.includes(downloadType)) {
      link.setAttribute("download", downloadFileName);
    } else {
      link.setAttribute("target", "_blank");
    }

    document.body.appendChild(link);

    link.click();

    setTimeout(() => {
      document.body.removeChild(link);
      (window.webkitURL || window.URL).revokeObjectURL(url);
    }, 200);
  } catch (error) {
    return stopDownload(status, "Impossibile scaricare il file");
  }
}

function getFilenameFromContentDisposition(contentDisposition: string) {
  if (!contentDisposition) return null;

  // Handle UTF-8 encoded filename
  const utf8FilenameRegex = /filename\*=UTF-8''([^'"\s][^;]*)/i;
  const utf8Match = contentDisposition.match(utf8FilenameRegex);

  if (utf8Match && utf8Match[1]) {
    return decodeURIComponent(utf8Match[1]);
  }

  // Handle standard filename
  const filenameRegex = /filename=["']?([^"';\n]*)/i;
  const standardMatch = contentDisposition.match(filenameRegex);

  if (standardMatch && standardMatch[1]) {
    return standardMatch[1];
  }

  return null;
}
